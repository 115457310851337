<template>
  <div class="main">
    <div id="loading">
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div class="object" id="object_one"></div>
          <div class="object" id="object_two"></div>
          <div class="object" id="object_three"></div>
          <div class="object" id="object_four"></div>
          <div class="object" id="object_five"></div>
          <div class="object" id="object_six"></div>
          <div class="object" id="object_seven"></div>
          <div class="object" id="object_eight"></div>
          <div class="object" id="object_big"></div>
        </div>
      </div>
    </div>
    <div class="txt">
      <div class="p">正在验证您的身份，请稍等...</div>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { htsyPortal } from "@/api/user";
import { clearStorage } from "@/utils/tools.js";
import { successCallback } from "@/utils/login.js";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      token: route.query.token || "",
      redirect: route.query.desturl || "",
    });

    clearStorage();
    htsyPortal({ token: state.token }).then((res) => {
      if (res.ret == 0) {
        successCallback(res, state);
      } else {
        setTimeout(() => {
          router.replace({ path: "/login_error" });
        }, 1000);
      }
    });
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/login.less";
</style>